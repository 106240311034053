import { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Link,
  Stack,
  Button,
  Tooltip,
  MenuItem,
  TableRow,
  TableCell,
  ListItemText,
} from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';

import { fDate } from 'src/utils/format-time';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

import ScreenshotView from './ScreenshotView';

// ----------------------------------------------------------------------

export default function MemberTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow }) {
  const popover = usePopover();
  // const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const {
    _id,
    name,
    createdAt,
    phone,
    email,
    user,
    member_id,
    is_paid,
    is_verified,
    current_club,
    verified_at,
    is_verified_processed,
  } = row;

  // const handleView = (id) => {
  //   navigate(paths.dashboard.course_view(id));
  // };

  const confirm = useBoolean();

  // eslint-disable-next-line consistent-return

  const handleStatus = () => {
    if (is_verified === false && is_paid === true) {
      return {
        label: 'Unverified',
        color: 'info',
      };
    }
    if (is_paid === false && is_verified === false) {
      return {
        label: 'Unpaid',
        color: 'warning',
      };
    }
    return {
      label: 'Verified',
      color: 'success',
    };
  };

  // const handleChangeVerify = () => {
  //   const updateCredential = {
  //     dispatch,
  //     memberId: _id,
  //   };
  //   dispatch(updateMemberStatus(updateCredential));
  // };

  const showPaymentScreenshotPopup = () => {
    setIsOpen(true);
  };

  // console.log(is_verified_processed);

  return (
    <>
      <ScreenshotView open={isOpen} setOpen={setIsOpen} data={row} />
      <TableRow hover selected={selected}>
        {/* <TableCell sx={{ whiteSpace: 'nowrap' }}>{createdAt}</TableCell> */}

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {member_id}&nbsp;&nbsp;
          {current_club && (
            <Label sx={{ px: 2 }} variant="soft" color="success" size="small">
              <Tooltip title="Club Name">{current_club?.club?.title}</Tooltip>
            </Label>
          )}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {' '}
          <ListItemText
            primary={
              <Link href={`${paths?.dashboard?.member_view(_id)}`} color="#7A4100">
                <Tooltip title="View Member Details">
                  <span>{name}</span>
                </Tooltip>
              </Link>
            }
            secondary={user?.username}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
          {/* <Link href={`${paths?.dashboard?.member_view(_id)}`} color="#7A4100">
            <Tooltip title="View Member Details">{name}</Tooltip>
          </Link> */}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{phone}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{email}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Label variant="soft" color={handleStatus()?.color || 'error'} size="small">
            {handleStatus()?.label}
          </Label>
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'flex-start' }}>
          {verified_at && is_verified === true && (
            <>
              {fDate(verified_at)}
              <br />
              Verified on
            </>
          )}

          {is_verified === false && is_paid === true && (
            <Stack flexDirection="row" alignItems="start">
              <>
                {/* <Tooltip title="View Payment Screenshot">
                  <CardMedia
                    onClick={() => showPaymentScreenshotPopup()}
                    component="img"
                    sx={{
                      fontSize: '14px',
                      width: 28,
                      height: 28,
                      lineHeight: 0,
                      borderRadius: '50%',
                      bgcolor: 'background.neutral',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      objectFit: 'contain',
                    }}
                    src={verifyImage}
                  />
                </Tooltip> */}
                &nbsp;
                {is_verified_processed === true ? (
                  <Tooltip title="Verification in progress">
                    <Box>
                      <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        // onClick={() => handleChangeVerify()}
                        disabled
                      >
                        Verify
                      </Button>
                    </Box>
                  </Tooltip>
                ) : (
                  <Button
                    variant="outlined"
                    color="success"
                    size="small"
                    onClick={() => showPaymentScreenshotPopup()}

                    // onClick={() => handleChangeVerify()}
                  >
                    Verify
                  </Button>
                )}
              </>
            </Stack>
          )}
          {is_paid === false && (
            <>
              {fDate(createdAt)}
              <br />
              Joined on
            </>
          )}
        </TableCell>
        {/* <TableCell>
          <Link href={`${paths?.dashboard?.member_view(_id)}`}>
            <Tooltip title="View">
              <Iconify icon="solar:eye-bold" sx={{ color: '#00A76F', cursor: 'pointer' }} />
            </Tooltip>
          </Link>
        </TableCell> */}
        {/* {!params?.id && (
          <TableCell align="right" sx={{ px: 1 }}>
            <IconButton color={popover.open ? 'inherit' : 'default'}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </TableCell>
        )} */}

        {/* <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Quick Edit" placement="top" arrow>
            <IconButton color={quickEdit.value ? 'inherit' : 'default'} onClick={quickEdit.onTrue}>
              <Iconify icon="solar:pen-bold" />
            </IconButton>
          </Tooltip>

          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell> */}
      </TableRow>
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}

MemberTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
