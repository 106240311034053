import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useState, useEffect, useCallback } from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { useResponsive } from 'src/hooks/use-responsive';

import { getSettingsDetails, updateSettingsDetails } from 'src/server/api/settings';

import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { FileUploadCustom } from 'src/components/CustomFields/ImageUpload';

// ----------------------------------------------------------------------

export default function SettingsForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mdUp = useResponsive('up', 'md');
  // const isMobile = useResponsive('down', 'md');

  const { settingsDetails, loading } = useSelector((state) => ({
    settingsDetails: state.settings.settingsDetails,
    loading: state.settings.loading,
  }));

  // console.log(settingsDetails);

  const [logoImagePreview, setLogoImagePreview] = useState([]);
  const [qrImagePreview, setQrImagePreview] = useState([]);

  // const NewJobSchema = Yup.object().shape({
  //   company: Yup.object().shape({
  //     name: Yup.string(),
  //     description: Yup.string(),
  //     company_logo: [],
  //     address: Yup.string(),
  //   }),
  //   account_credentials: Yup.object().shape({
  //     wallet_id: Yup.string(),
  //     qr_code: [],
  //   }),
  //   contact_details: Yup.object().shape({
  //     phone: Yup.string()
  //       .required('Phone required')
  //       .min(10, 'Phone must be at least 10 characters')
  //       .max(15, 'Phone must not exceed 15 characters'),
  //     email: Yup.string().email(),
  //     whatsapp_number: Yup.string(),
  //     website: Yup.string(),
  //   }),
  //   social_links: Yup.object().shape({
  //     whatsapp: Yup.number(),
  //     instagram: Yup.string(),
  //     facebook: Yup.string(),
  //     x: Yup.string(),
  //   }),
  //   member_id_next_value: Yup.string(),
  //   member_id_tag: Yup.string(),
  //   membership_amount: Yup.string(),
  //   currency: Yup.string(),
  // });

  useEffect(() => {
    setLogoImagePreview(settingsDetails?.company?.company_logo);
    setQrImagePreview(settingsDetails?.account_credentials?.qr_code);
  }, [settingsDetails]);

  const defaultValues = useMemo(
    () => ({
      company: {
        name: settingsDetails?.company?.name,
        description: settingsDetails?.company?.description,
        address: settingsDetails?.company?.address,
        company_logo: logoImagePreview,
      },
      account_credentials: {
        wallet_id: settingsDetails?.account_credentials?.wallet_id,
        qr_code: qrImagePreview,
        wallet_name: settingsDetails?.account_credentials?.wallet_name,
      },
      contact_details: {
        phone: settingsDetails?.contact_details?.phone,
        email: settingsDetails?.contact_details?.email,
        whatsapp_number: settingsDetails?.contact_details?.whatsapp_number,
        website: settingsDetails?.contact_details?.website,
      },
      social_links: {
        whatsapp: settingsDetails?.social_links?.whatsapp,
        instagram: settingsDetails?.social_links?.instagram,
        facebook: settingsDetails?.social_links?.facebook,
        x: settingsDetails?.social_links?.x,
      },
      currency: settingsDetails?.currency,
      member_id_next_value: settingsDetails?.member_id_next_value,
      member_id_tag: settingsDetails?.member_id_tag,
      membership_amount: settingsDetails?.membership_amount,
    }),
    [settingsDetails, qrImagePreview, logoImagePreview]
  );

  const methods = useForm({
    // resolver: yupResolver(NewJobSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (settingsDetails) {
      reset(defaultValues);
    }
  }, [settingsDetails, defaultValues, reset]);

  useEffect(() => {
    const credentials = {
      dispatch,
    };
    dispatch(getSettingsDetails(credentials));
  }, [dispatch]);

  const onSubmit = handleSubmit(async (data) => {
    const settingsId = settingsDetails?._id;

    // console.log(data);

    const credentials = {
      state: data,
      settingsId,
      dispatch,
      navigate,
    };
    dispatch(updateSettingsDetails(credentials));
  });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('image', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const companyDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Company Details
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Modify the Company details in this section
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Details" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <RHFTextField InputLabelProps={{ shrink: true }} name="company.name" label="Name" />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="company.address"
                label="Address"
              />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="company.description"
                multiline
                rows={4}
                label="Description"
              />
            </Stack>
            <Typography variant="body2">Company Logo</Typography>
            <FileUploadCustom
              uploadUrl="settings"
              folder="logo"
              setImagesPreview={setLogoImagePreview}
              imagesPreview={logoImagePreview}
              title="Upload Image"
              isMultiple
              imageSize={4}
              theme={{
                type: 'standard',
                name: 'image',
                handleDrop,
              }}
            />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const paymentDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Payment Details
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Modify the Payment details in this section
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Details" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="account_credentials.wallet_id"
                label="Wallet Id"
              />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="account_credentials.wallet_name"
                label="Wallet Name"
              />
            </Stack>
            <Typography variant="body2">QR Code</Typography>
            <FileUploadCustom
              uploadUrl="settings"
              folder="settings"
              setImagesPreview={setQrImagePreview}
              imagesPreview={qrImagePreview}
              title="Upload Image"
              isMultiple
              imageSize={4}
              theme={{
                type: 'standard',
                name: 'image',
                handleDrop,
              }}
            />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const contactDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Contact Details
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Modify the Contact details in this section
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Details" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="contact_details.phone"
                label="Phone"
              />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="contact_details.email"
                label="Email"
              />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="contact_details.whatsapp_number"
                label="Whatsapp Number"
              />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="contact_details.website"
                label="Website"
              />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const socialDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Social Links
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Modify the Social Links if necessary
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Details" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="social_links.whatsapp"
                label="Whatsapp"
              />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="social_links.instagram"
                label="Instagram"
              />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="social_links.facebook"
                label="Facebook"
              />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField InputLabelProps={{ shrink: true }} name="social_links.x" label="X" />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  // const commenDetails = (
  //   <>
  //     {mdUp && (
  //       <Grid md={4}>
  //         <Typography variant="h6" sx={{ mb: 0.5 }}>
  //           Common
  //         </Typography>
  //         <Typography variant="body2" sx={{ color: 'text.secondary' }}>
  //           Common Details
  //         </Typography>
  //       </Grid>
  //     )}

  //     <Grid xs={12} md={8}>
  //       <Card>
  //         {!mdUp && <CardHeader title="Details" />}

  //         <Stack spacing={3} sx={{ p: 3 }}>
  //           <Grid container>
  //             <Grid item md={6} xs={12}>
  //               <Stack spacing={1.5}>
  //                 <RHFTextField
  //                   InputLabelProps={{ shrink: true }}
  //                   name="currency"
  //                   label="Currency"
  //                 />
  //               </Stack>
  //             </Grid>
  //             <Grid item md={6} xs={12}>
  //               <Stack spacing={1.5} pl={!isMobile && 1} pt={isMobile && 2}>
  //                 <RHFTextField
  //                   InputLabelProps={{ shrink: true }}
  //                   name="membership_amount"
  //                   label="Membership Amount"
  //                 />
  //               </Stack>
  //             </Grid>
  //           </Grid>

  //           <Grid container>
  //             <Grid item md={6} xs={12}>
  //               <Stack spacing={1.5}>
  //                 <RHFTextField
  //                   InputLabelProps={{ shrink: true }}
  //                   name="member_id_tag"
  //                   label="Member Id Tag"
  //                 />
  //               </Stack>
  //             </Grid>
  //             <Grid item md={6} pl={!isMobile && 1} xs={12} pt={isMobile && 2}>
  //               <Stack spacing={1.5}>
  //                 <RHFTextField
  //                   InputLabelProps={{ shrink: true }}
  //                   name="member_id_next_value"
  //                   label="Member Id Next"
  //                 />
  //               </Stack>
  //             </Grid>
  //           </Grid>
  //         </Stack>
  //       </Card>
  //     </Grid>
  //   </>
  // );

  // const examDetails = (
  //   <>
  //     {mdUp && (
  //       <Grid md={4}>
  //         <Typography variant="h6" sx={{ mb: 0.5 }}>
  //           Exam
  //         </Typography>
  //         <Typography variant="body2" sx={{ color: 'text.secondary' }}>
  //           Exam Details
  //         </Typography>
  //       </Grid>
  //     )}

  //     <Grid xs={12} md={8}>
  //       <Card>
  //         {!mdUp && <CardHeader title="Details" />}

  //         <Stack direction="column" spacing={3} sx={{ p: 3 }}>
  //           <Stack spacing={1.5} width="100%">
  //             <RHFTextField
  //               InputLabelProps={{ shrink: true }}
  //               name="exam_introduction"
  //               label="Exam Introduction"
  //               multiline={4}
  //             />
  //           </Stack>
  //           <Stack spacing={1.5}>
  //             <RHFTextField
  //               InputLabelProps={{ shrink: true }}
  //               name="question_limit"
  //               label="Questions per page"
  //             />
  //           </Stack>
  //         </Stack>
  //       </Card>
  //     </Grid>
  //   </>
  // );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Stack sx={{ width: '100%' }} />
        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting || loading}
          sx={{ ml: 2, width: '250px' }}
        >
          Save Changes
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {companyDetails}

        {paymentDetails}

        {contactDetails}

        {socialDetails}

        {/* {commenDetails} */}

        {/* {examDetails} */}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
