import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { CardMedia } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { usePathname } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useResponsive } from 'src/hooks/use-responsive';

import Twitter from 'src/assets/social/twitter.png';
import LinkedIn from 'src/assets/social/linkdIn.png';
import facebook from 'src/assets/social/facebook.png';
import instragram from 'src/assets/social/instagram.png';

import Logo from 'src/components/logo';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Winbuks',
    children: [
      { name: 'About', href: paths.public?.landing },
      { name: 'Contact us', href: paths.public?.contact },
      { name: 'Plan', href: paths.public.plan },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Conditions', href: paths.public?.terms_conditions },
      { name: 'Privacy Policy', href: paths?.public?.privacy_policy },
    ],
  },
  {
    headline: 'Contact',
    children: [
      { name: ' info@winbuks.io', href: '#' },
      // { name: 'Admin', href: paths.auth.login },
    ],
  },
];

// ----------------------------------------------------------------------

export default function Footer() {
  const pathname = usePathname();

  const homePage = pathname === '/';
  const mdUp = useResponsive('up', 'md');

  // const simpleFooter = (
  //   <Box
  //     component="footer"
  //     sx={{
  //       py: 5,
  //       textAlign: 'center',
  //       position: 'relative',
  //       bgcolor: 'background.default',
  //     }}
  //   >
  //     <Container>
  //       <Logo sx={{ mb: 1, mx: 'auto' }} />

  //       <Typography variant="caption" component="div">
  //         © All rights reserved
  //         <br /> made by
  //         <Link href="https://minimals.cc/"> minimals.cc </Link>
  //       </Typography>
  //     </Container>
  //   </Box>
  // );

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        // bgcolor: 'background.default',
        // backgroundColor: 'black',
        background: 'linear-gradient(to bottom, #171716 4%, black 22%)',
      }}
    >
      {/* <Divider /> */}

      <Container
        sx={{
          pt: 10,
          pb: 5,
          textAlign: { xs: 'center', md: 'unset' },
        }}
      >
        {mdUp && <Logo sx={{ mb: 3 }} type="smallGolden" />}
        {!mdUp && (
          <Stack alignItems="center" mb={2}>
            <Logo type="smallGolden" sx={{ height: '28px', width: '28px' }} />
          </Stack>
        )}

        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
        >
          <Grid xs={8} md={3}>
            <Typography
              variant="body2"
              fontWeight="400"
              sx={{
                maxWidth: 270,
                mx: { xs: 'auto', md: 'unset' },
                color: '#FEDFA1',
              }}
            >
              Winbuks, based in Singapore, offers global career opportunities and exceptional
              earning potential. We redefine lifestyles by leveraging our industry expertise to
              create lasting business opportunities for all.
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{
                mt: 3,
                mb: { xs: 5, md: 0 },
              }}
            >
              <IconButton
                sx={{
                  '&:hover': {
                    // bgcolor: alpha(social.color, 0.08),
                  },
                }}
              >
                <CardMedia component="img" src={facebook} alt="copy" sx={{ width: '13px' }} />
              </IconButton>
              <IconButton
                sx={{
                  '&:hover': {
                    // bgcolor: alpha(social.color, 0.08),
                  },
                }}
              >
                <CardMedia component="img" src={instragram} alt="copy" sx={{ width: '20px' }} />
              </IconButton>
              <IconButton
                sx={{
                  '&:hover': {
                    // bgcolor: alpha(social.color, 0.08),
                  },
                }}
              >
                <CardMedia component="img" src={LinkedIn} alt="copy" sx={{ width: '20px' }} />
              </IconButton>
              <IconButton
                sx={{
                  '&:hover': {
                    // bgcolor: alpha(social.color, 0.08),
                  },
                }}
              >
                <CardMedia component="img" src={Twitter} alt="copy" sx={{ width: '20px' }} />
              </IconButton>
            </Stack>
          </Grid>

          <Grid xs={12} md={6}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }}>
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                  sx={{ width: 1 }}
                >
                  <Typography component="div" variant="overline" fontWeight={700} color="#FEDFA1">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      key={link.name}
                      component={RouterLink}
                      href={link.href}
                      color="#FEDFA1"
                      fontWeight={400}
                      variant="body2"
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography variant="body2" sx={{ mt: 10, color: '#FEDFA1', fontWeight: '400' }}>
          © 2024. All rights reserved
        </Typography>
      </Container>
    </Box>
  );

  return homePage ? mainFooter : mainFooter;
}
